<template>
    <main>
        <div class="content-wrapper-outer">
			<div class="content-wrapper-inner">
				<div class="content">
					<Headline />
					<p v-if="firstLoad">See how fast a Hyperloop really is by comparing the travel time between two locations with traditional modes of transport like the car, train and plane.</p>
					<p v-if="!firstLoad">The travel time is calculated based on the distance and average loading/unloading time for each vehicle.</p>
					<p v-if="distance === 0" class="text">Click anywhere on the map to select a start and end location!</p>
					<Result v-if="distance !== 0" :distance="distance" />
				</div>
			</div>
        </div>

		<div class="map-wrapper">
			<Map />
		</div>
    </main>
</template>

<script>
import Map from '@/components/Map'
import Headline from '@/components/Headline'
import Result from '@/components/Result'

export default {
	name: 'Home',
	data() {
		return {
			firstLoad: true
		}
	},
	components: {
		Map,
		Headline,
		Result
	},
	computed: {
		distance() {
			return this.$store.state.distance
		}
	},
	watch: {
		distance(newValue) {
			if (newValue !== 0) {
				this.firstLoad = false
			}
		}
	}
}
</script>

<style>
    .main {
        animation: fadein .2s;
		height: 100%;
		width: 100%;
    }

	.content-wrapper-outer {
		position: absolute;
		top: 0;
		left: 0;
		width: 700px;
		max-height: 100%;
		padding: 2rem;
		z-index: 1;
		box-sizing:border-box;
	}

	.content-wrapper-inner {
		background: #191A1A;
		height: 100%;
		border-radius: 15px;
		box-shadow: 0px 0px 20px -5px rgba(0,0,0,0.75);
	}

	@media screen and (max-width: 950px) {
		.content-wrapper-outer {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			max-width: 700px;
			min-width: 510px;
			width: 95%;
			padding: 2rem;
			z-index: 1;
			box-sizing:border-box;
		}
	}

	.content {
		padding: 3rem;
		padding-top: 2rem;
		text-align: justify;
	}

	.content p {
		color: var(--font-light);
	}

	.text {
		margin-top: 2rem;
		margin-bottom: -1rem;
		text-align: center;
		color: var(--font) !important;
	}

    @keyframes fadein {
		from {
			opacity: 0.2;
		}

		to {
			opacity: 1;
		}
	}

	.map-wrapper {
		position: fixed;
		z-index: 0;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
	}
</style>