<template>
	<div class="headline">
		<h1>Hyperloop vs <span>{{ headlineVehicles[current] }}</span></h1>
		<hr>
	</div>
</template>

<script>
export default {
	name: 'Headline',
	data() {
		return {
			current: 0,
			headlineVehicles: [ 'Train', 'Plane', 'Car' ]
		}
	},
	mounted() {
		setInterval(() => {
			this.current += 1
			if (this.current > this.headlineVehicles.length - 1) this.current = 0
		}, 2000)
	}
}
</script>

<style>
    .headline {
		margin-bottom: 2rem;
	}

	.headline h1 {
		margin: 0;
		text-align: center;
		color: var(--font);
	}

	.headline span {
		color: var(--primary);
		width: 100px;
		display: inline-block;
		text-align: left;
	}

	.headline hr {
		width: 400px;
		height: 4px;
		background: var(--background-light);
		border: 0;
	}
</style>