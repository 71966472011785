<template>
	<div class="items">
		<ResultIcon name="Distance" :value="distance + ' KM'" />
		<ResultIcon name="Car" :value="getTime('car')" />
		<ResultIcon name="Train" :value="getTime('train')" />
		<ResultIcon name="Plane" :value="getTime('plane')" />
		<ResultIcon name="Hyperloop" :value="getTime('hyperloop')" />
	</div>
</template>

<script>
import humanizeDuration from 'humanize-duration'

import ResultIcon from '@/components/ResultItem'

export default {
	name: 'Result',
	data() {
		return {
			hyperloop: {
				speed: 1200,
				enterExit: 15 / 60
			},
			plane: {
				speed: 650,
				enterExit: 120 / 60
			},
			train: {
				speed: 160,
				enterExit: 15 / 60
			},
			car: {
				speed: 80,
				enterExit: 10 / 60
			}
		}
	},
	props: [ 'distance' ],
	methods: {
		getTime(veh) {
			const timeInHours = (this.distance / this[veh].speed) + this[veh].enterExit
			console.log(timeInHours * 3600000)
			return humanizeDuration((timeInHours * 3600000), { largest: 1, round: true })
		}
	},
	components: {
		ResultIcon
	}
}
</script>

<style scoped>
	.items {
		display: flex;
		align-items: center;
		justify-content: center;
	}
</style>