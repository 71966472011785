<template>
	<l-map id="map" ref="map" v-if="showMap" :zoom="zoom" :zoomControl="false" :center="center" :options="mapOptions" @ready="ready()" @click="click" @update:center="centerUpdate" @update:zoom="zoomUpdate">
		<l-tile-layer
        :url="url"
        :attribution="attribution"
      />
	<l-marker v-for="marker, index in markers" :key="index" :lat-lng="marker"></l-marker>
	</l-map>
</template>

<script>
import L from 'leaflet'
import {
	LMap,
	LTileLayer,
	LMarker
} from 'vue2-leaflet'

export default {
	name: 'Map',
	components: {
		LMap,
		LTileLayer,
		LMarker
	},
	data() {
		return {
			zoom: 6,
			center: L.latLng(47.663802, 9.176164),
			url: 'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png',
			attribution: '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
			withPopup: L.latLng(47.41322, -1.219482),
			withTooltip: L.latLng(47.41422, -1.250482),
			currentZoom: 11.5,
			currentCenter: L.latLng(47.41322, -1.219482),
			showParagraph: false,
			markers: [],
			line: undefined,
			mapOptions: {
				zoomSnap: 0.5,
				zoomControl: false
			},
			showMap: true,
			allowClick: true,
			timeout: null
		}
	},
	methods: {
		ready() {
			this.map = this.$refs.map.mapObject
		},
		click(e) {
			if (!this.allowClick) {
				return
			}

			this.allowClick = false

			this.timeout = setTimeout(() => {
				this.allowClick = true
			}, 200)

			if (this.line) {
				this.markers = []
				this.line.remove(this.map)
				this.line = undefined
				this.$store.commit('setDistance', 0)
			}

			if (this.markers.length < 2) {
				this.markers.push(e.latlng)
			}

			if (this.markers.length === 2) {
				const latlngs = []

				latlngs.push(this.markers[0])
				latlngs.push(this.markers[1])

				this.line = L.polyline(latlngs, { color: '#F24D4D' }).addTo(this.map)

				this.$store.commit('setDistance', (this.markers[0].distanceTo(this.markers[1]).toFixed(0) / 1000))

				return
			}
		},
		zoomUpdate(zoom) {
			this.currentZoom = zoom
		},
		centerUpdate(center) {
			this.currentCenter = center
		}
	},
	beforeDestroy() {
		clearTimeout(this.timeout)
	}
}
</script>

<style scoped>
#map {
	background: var(--background-light);
}
</style>