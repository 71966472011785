<template>
	<div class="item">
        <h4>{{ name }}</h4>
        <p>{{ value }}</p>
	</div>
</template>

<script>
export default {
	name: 'Result',
	props: [ 'name', 'value' ]
}
</script>

<style scoped>
	.item {
       margin: 0 1rem;
    }

    .item h4 {
        margin-bottom: 0;
    }

    .item p {
        margin: 0;
        margin-top: 0.5rem;
    }
</style>